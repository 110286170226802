<template>
  <el-dialog title="反馈表" :visible.sync="show" width="566px" @close="exit">
    <div
      class="bg"
      v-loading="isdone"
      element-loading-text="正在生成文件并上传中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="phonemain">
        <div class="table-main">
          <div id="html2canvas" ref="html2canvas" style="background-color: rgb(248, 248, 248);">
            <div class="list-content" v-if="list.length > 0">
              <div class="bianhao">
                <p>
                  编号：<span>{{ feedbackid }}</span>
                </p>
              </div>
              <div class="bianhao">
                <p style="border-bottom: 1px dashed #d0d0d0;padding-bottom: 2px;padding-left:14px;">
                  ID：<span>{{ usercustID }}</span
                  ><span style="float:right;">{{ filetime(createtime) }}</span>
                </p>
              </div>
              <div>
                <div>
                  <h3>基本信息</h3>
                </div>
                <div>
                  <van-field border v-model="custmsg.Cust_Name" label="姓名：" :disabled="!noedit">
                  </van-field>
                </div>
                <div>
                  <van-field
                    border
                    v-model="custmsg.Cust_Sex == 0 ? '男' : '女'"
                    label="性别："
                    :disabled="!noedit"
                  >
                  </van-field>
                </div>
                <div>
                  <van-field border v-model="custmsg.Cust_Age" label="年龄：" :disabled="!noedit">
                  </van-field>
                </div>
                <div>
                  <van-field
                    border
                    v-model="custmsg.Cust_Height"
                    label="身高(CM)："
                    class="cwidh"
                    :disabled="!noedit"
                  >
                  </van-field>
                </div>
                <div>
                  <van-field
                    border
                    v-model="custmsg.Cust_Weight"
                    label="体重(KG)："
                    class="cwidh"
                    :disabled="!noedit"
                  >
                  </van-field>
                </div>
              </div>
              <div>
                <h3></h3>
              </div>
              <div v-for="(item, index) in list" :key="index">
                <div v-if="item.KFiel_Type == 0">
                  <h3>{{ item.KFiel_ShowCaption }}</h3>
                </div>
                <div v-if="item.KFiel_Type == 1">
                  <van-field
                    v-if="item.KFiel_ShowCaption == '姓名' || item.KFiel_ShowCaption == '性别'"
                    border
                    v-model="item.Values"
                    :label="item.KFiel_ShowCaption + '：'"
                    :placeholder="'请输入' + item.KFiel_ShowCaption"
                    :rules="[{ required: true, message: '请填写用户名' }]"
                    class="cwidh"
                    :disabled="!noedit"
                  >
                  </van-field>
                  <van-field
                    v-else
                    border
                    v-model="item.Values"
                    :label="item.KFiel_ShowCaption + '：'"
                    :placeholder="'请输入' + item.KFiel_ShowCaption"
                    :rules="[{ required: true, message: '请填写用户名' }]"
                    :disabled="!noedit"
                  >
                  </van-field>
                </div>
                <div v-if="item.KFiel_Type == 2">
                  <van-field
                    border
                    v-model="item.Values"
                    :label="item.KFiel_ShowCaption + '：'"
                    type="textarea"
                    autosize
                    :placeholder="'请输入' + item.KFiel_ShowCaption"
                    :rules="[{ required: true, message: '请填写用户名' }]"
                    :disabled="!noedit"
                  >
                  </van-field>
                </div>
                <div v-if="item.KFiel_Type == 5 || item.KFiel_Type == 7">
                  <van-field :name="item.KFiel_ShowCaption">
                    <template #input>
                      <div style="width: 100%; box-sizing: border-box">
                        <div class="doc-title">
                          {{ item.KFiel_ShowCaption + "：" }}
                        </div>
                        <div class="doc-list">
                          <van-radio-group v-model="item.Values" :disabled="!noedit">
                            <van-radio
                              v-for="(it, idx) in item.checklist"
                              @click="check2(it)"
                              :key="idx"
                              :name="it.KFValues"
                              style="margin-bottom: 10px"
                            >
                              {{ it.KFValues }}
                            </van-radio>
                          </van-radio-group>
                        </div>
                      </div>
                    </template>
                  </van-field>
                </div>
                <div v-if="item.KFiel_Type == 6">
                  <van-field :name="item.KFiel_ShowCaption">
                    <template #input>
                      <div style="width: 100%; box-sizing: border-box">
                        <div class="doc-title">
                          {{ item.KFiel_ShowCaption + "：" }}
                        </div>
                        <div class="doc-list">
                          <van-checkbox-group v-model="item.list" :disabled="!noedit">
                            <van-checkbox
                              v-for="(it, idx) in item.checklist"
                              @click="check(item.list, item)"
                              :key="idx"
                              shape="square"
                              :name="it.KFValues"
                              style="margin-bottom: 10px"
                            >
                              {{ it.KFValues }}</van-checkbox
                            >
                          </van-checkbox-group>
                        </div>
                      </div>
                    </template>
                  </van-field>
                </div>
                <div v-if="item.KFiel_Type == 8">
                  <h5>{{ item.KFiel_ShowCaption }}</h5>
                </div>
                <div v-if="item.KFiel_Type == 9">
                  <van-field
                    v-if="
                      item.KFiel_ShowCaption == '年龄' ||
                        item.KFiel_ShowCaption == '身高(CM)' ||
                        item.KFiel_ShowCaption == '体重(KG)'
                    "
                    border
                    type="digit"
                    v-model="item.Values"
                    :label="item.KFiel_ShowCaption + '：'"
                    :placeholder="'请输入' + item.KFiel_ShowCaption"
                    class="cwidh"
                    :rules="[{ required: true, message: '请填写用户名' }]"
                    :disabled="!noedit"
                  >
                  </van-field>
                  <van-field
                    v-else
                    border
                    type="digit"
                    v-model="item.Values"
                    :label="item.KFiel_ShowCaption + '：'"
                    :placeholder="'请输入' + item.KFiel_ShowCaption"
                    :rules="[{ required: true, message: '请填写用户名' }]"
                    :disabled="!noedit"
                  >
                  </van-field>
                </div>
              </div>
            </div>
            <div v-if="list2.length > 0">
              <div class="box box2">
                <div style="padding-top: 10px"></div>
                <div class="list-label" style="margin: 10px">问诊详情</div>
                <div class="doc-item flex-row">
                  <div class="doc-key">
                    <p>问诊编号</p>
                  </div>
                  <div class="doc-value">
                    <p>{{ id }}</p>
                  </div>
                </div>
                <div class="doc-item flex-row" v-for="(item, index) in list2" :key="index">
                  <div class="doc-key">
                    <p>{{ item.KFiel_ShowCaption }}：</p>
                  </div>
                  <div class="doc-value">
                    <p>{{ item.KFValues }}</p>
                  </div>
                </div>
                <div class="half-slot"></div>
              </div>
            </div>
            <div class="box box2">
              <div class="box-container">
                <div class="list-label">
                  复诊报告<span style="margin-left: 10px;color:#A9A9A9;font-size: 14px;"
                    >(仅供医生查看)</span
                  >
                </div>
                <div class="img-box">
                  <div class="item" v-for="(item, index) in photo" :key="index">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="photoFilter(item)"
                      :preview-src-list="photoList"
                    >
                    </el-image>
                    <div class="close-btn" @click="removeImg(index)" v-if="noedit">
                      <van-icon name="cross" color="#fff" size="20px"> </van-icon>
                    </div>
                  </div>
                  <div class="item" v-if="noedit">
                    <van-uploader :after-read="upload" :before-read="beforeAvatarUpload">
                      <span class="add-pic">点击上传</span>
                    </van-uploader>
                  </div>
                  <br style="clear: both;" />
                </div>
              </div>
            </div>
            <div class="box" v-if="state == 6">
              <div style="padding-top:10px;"></div>
              <div class="list-label" style="margin:10px">处方</div>
              <div style="padding: 10px;box-sizing: border-box;text-align:left;">
                <div class="note" v-if="drugdetail.length > 0">
                  <div v-for="(item, index) in drugdetail" :key="index">
                    <van-divider>处方{{ index + 1 }}</van-divider>
                    <van-row class="chufang">
                      <van-col span="8">药品信息：</van-col>
                      <van-col span="16" v-html="cartsfilter(item.carts)">{{
                        cartsfilter(item.carts)
                      }}</van-col>
                    </van-row>
                    <van-row class="chufang" v-if="pharmacytype(item.doctor_pharmacy)">
                      <van-col span="8">服用剂量：</van-col>
                      <van-col span="16"
                        >{{ shapefilter(item.doctor_shape) }}共{{ item.doctor_dosage }}剂</van-col
                      >
                    </van-row>
                    <van-row class="chufang">
                      <van-col span="8">用法用量：</van-col>
                      <van-col
                        span="16"
                        v-html="pharmacyfilter(item.doctor_pharmacy, item.doctor_shape)"
                      ></van-col>
                    </van-row>
                    <van-row class="chufang">
                      <van-col span="8">小计：</van-col>
                      <van-col span="16">{{ item.amount_str }}元</van-col>
                    </van-row>
                  </div>
                  <van-divider />
                  <div>
                    <van-row class="chufang">
                      <van-col span="8">合计：</van-col>
                      <van-col span="16">{{ hejiprice }}元</van-col>
                    </van-row>
                  </div>
                </div>
              </div>
              <div class="list-label" style="margin:10px">方案</div>
              <div style="padding: 10px;box-sizing: border-box;text-align:left;">
                <van-divider v-if="YSTZFA != ''">运动/饮食方案</van-divider>
                <p class="note">{{ YSTZFA }}</p>
                <van-divider v-if="YDFA != '' && drugdetail == ''">治疗方案</van-divider>
                <div class="note" v-if="YDFA != '' && drugdetail == ''">
                  <p>{{ YDFA }}</p>
                </div>
                <van-divider v-if="LCZD != '' || drugdetail != ''">临床诊断</van-divider>
                <div class="note" v-if="LCZD != '' || drugdetail != ''">
                  <p>{{ LCZD }}</p>
                </div>
                <van-divider v-if="YQXG != ''">预期效果</van-divider>
                <div class="note" v-if="YQXG != ''">
                  <p>{{ YQXG }}</p>
                </div>
                <van-divider v-if="BZ != ''">指导意见</van-divider>
                <p class="note">{{ BZ }}</p>
              </div>
            </div>
          </div>
          <div style="margin: 16px">
            <van-button round block type="info" @click="copy()">
              复制链接
            </van-button>
          </div>
          <div style="margin: 16px" v-if="noedit && state != 6">
            <van-button
              round
              block
              type="info"
              v-if="state == 0 || state == 1"
              native-type="submit"
              @click="save('save')"
            >
              保存
            </van-button>
          </div>
          <div style="margin: 16px" v-if="noedit && state != 6">
            <van-button
              v-if="state == 0 || state == 1"
              round
              block
              type="info"
              native-type="submit"
              @click="save('sub')"
            >
              提交
            </van-button>
          </div>
        </div>
      </div>
      <shenhebtn
        ref="pdfbtn"
        :shenhe.sync="shenhe"
        :state="state"
        :kid="feedbackid"
        @shbsctp="shbsctp"
      ></shenhebtn>
    </div>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "../assets/biaostyle.css";
import shenhebtn from "../components/shenhebtn.vue";
Vue.use(Vant);
export default {
  props: ["lookfeedback", "feedbackid", "feedbacknoedit", "shenhe", "fkshenhetcout", "iskefu"],
  watch: {
    lookfeedback(o, n) {
      this.show = o;
      if (o) {
        this.custmsg = "";
        this.getdetail();
      }
    },
    feedbacknoedit(o, n) {
      this.noedit = o;
    },
  },
  components: {
    shenhebtn,
  },
  data() {
    return {
      show: false,
      list: [],
      list2: "",
      photo: [],
      state: "",
      serve_id: sessionStorage.getItem("servicer_id"),
      noedit: true,
      usercustID: "",
      carts: "",
      doctor_dosage: "",
      doctor_pharmacy: "",
      drugdetail: "",
      createtime: "",
      isdone: false,
      YDFA: "",
      hejiprice: "",
      // doctor_disease:"", //病因信息
      // doctor_dialectic:"",// 预期效果
      YQXG: "",
      custmsg: {},
      photoList:[],
    };
  },
  mounted() {},
  methods: {
    getusermag(custid) {
      this.axios.post("/gu/search_gu_custs", this.qs.stringify({ keyword: custid })).then((res) => {
        if (res.data.code == 0) {
          this.custmsg = res.data.result[0];
          console.log(this.custmsg);
          this.custmsg.Cust_Sex == 0 ? "男" : "女";
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    filetime(val) {
      return val.substr(2, 9);
    },
    exit() {
      this.show = false;
      this.$emit("update:lookfeedback", false);
    },
    removeImg(index) {
      this.photo.splice(index, 1);
    },
    check(list, item) {
      item.Values = "";
      item.Values = list.join(";");
    },
    check2(item) {
      item.Values = item.KFValues;
    },
    photoFilter(src) {
      var url = "http://192.168.11.253:8080/public/" + src;
      return url;
    },
    photoFilter2(src) {
      var url = "http://192.168.11.253:8080/public/" + src;
      // var arr = [];
      // arr.push(url);
      // return arr;
      this.photoList.push(url);
    },
    upload(content) {
      const loading = this.$loading({
        lock: true,
        text: "图片上传中，请等待...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let formData = new FormData();
      formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
      this.axios
        .post("http://192.168.11.253:8080/qiniu/examples/upload_simple_file3.php", formData, {
          timeout: 30000,
        })
        .then((rs) => {
          if (rs.data.code == 0) {
            console.log(rs);
            this.photo.push(rs.data.file);
            loading.close();
          } else {
            this.$message.error(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isLt2M;
    },
    getdetail() {
      this.list = [];
      var str = "";
      if (this.iskefu == "no") {
        str = "&input_id=" + sessionStorage.getItem("servicer_id");
      }
      this.axios
        .get("/field/get_view_newgid_by_fankui?kid=" + this.feedbackid + str)
        .then((res) => {
          this.usercustID = res.data.dismain.Cust_ID;
          this.getusermag(this.usercustID);
          this.createtime = res.data.dismain.Creat_DT;
          if (res.data.code == 0) {
            this.dismain = res.data.dismain;
            if (res.data.code == "0") {
              if (res.data.dismain.State == 9) {
                this.state = 9;
                this.success = true;
              }
              var photo = res.data.photo == null ? [] : res.data.photo;
              var fiels = res.data.fiels == null ? [] : res.data.fiels;
              this.photo = photo.map((item) => item.FileName);
              this.state = res.data.dismain.State;
              // if (res.data.dismain.State == 0 || res.data.dismain.State == 6) {
              for (let i = 0; i < res.data.result.length; i++) {
                var item = res.data.result[i];
                item.Values = "";
                item.Values = "";
                if (item.checklist) {
                  item.list = [];
                }
                for (let index = 0; index < fiels.length; index++) {
                  var element = fiels[index];
                  if (element.KFiel_ID == item.KFiel_ID) {
                    item.Values = element.KF_Values;
                    if (item.checklist) {
                      item.list = element.KF_Values.split(";");
                    }
                    continue;
                  }
                }
                this.list.push(item);
              }
              this.list = this.list.sort((a, b) => {
                return a.KOrder - b.KOrder;
              });
              this.$nextTick(() => {
                this.showBtn = true;
                this.success = true;
                if (this.noedit == "no") {
                  this.showBtn = false;
                }
              });
              if (this.state == 6) {
                if (res.data.fa != null && res.data.fa != "") {
                  this.YSTZFA = res.data.fa.YSTZFA == null ? "" : res.data.fa.YSTZFA;
                  this.LCZD = res.data.fa.LCZD == null ? "" : res.data.fa.LCZD;
                  this.YDFA = res.data.fa.YDFA == null ? "" : res.data.fa.YDFA;
                  this.BZ = res.data.fa.BZ == null ? "" : res.data.fa.BZ;
                  this.YQXG = res.data.fa.YQXG == null ? "" : res.data.fa.YQXG;
                  this.getyaofang(this.feedbackid);
                } else {
                  this.YSTZFA = "";
                  this.LCZD = "";
                  this.BZ = "";
                }
              }
              // if(res.data.docpres_details != null){
              //   this.drugdetail = res.data.docpres_details;
              // this.doctor_disease = this.drugdetail[0].doctor_disease
              // this.doctor_dialectic = this.drugdetail[0].doctor_dialectic;
              // }
              this.photo.forEach(ele=>{
                this.photoFilter2(ele);
              })
            } else {
            }
          }
        });
    },
    getyaofang(kid) {
      this.axios.post("docpres/get_gu_docpres_order_by_kid?kid=" + kid).then((res) => {
        if (res.data.code == 0) {
          if (res.data.result != null) {
            this.drugdetail = res.data.result;
          }
          this.hejiprice = res.data.amount;
        } else {
          this.drugdetail = [];
          this.hejiprice = "";
        }
      });
    },
    cartsfilter(val) {
      if (val != "" && val != null && val != undefined) {
        val = JSON.parse(val);
        var str = "";
        for (let a = 0; a < val.length; a++) {
          const element = val[a];
          str += element.title + "：" + element.num + element.grams + "；" + "<br/>";
        }
        return str;
      }
    },
    shapefilter(val) {
      var dic = {
        1: "内服",
        2: "外用",
      };
      return dic[val];
    },
    pharmacytype(val) {
      var type = false;
      if (val != "" && val != null && val != undefined) {
        val = JSON.parse(val);
      }
      if (val.type == 0 || val.type == undefined) {
        type = true;
      } else {
        type = false;
      }
      return type;
    },
    pharmacyfilter(val, shape) {
      var str = "";
      if (val != "" && val != null && val != undefined) {
        val = JSON.parse(val);
      }
      var dic = {
        meiRiJiShu: "每日%d%r",
        meiJiCiShu: "每%s%d%r",
        zuiXiaoZhongLiang: "最小重量",
        zuiDaZhongLiang: "最大重量",
        meiRiCiShu: "每日次数",
        meiRiKeShu: "每日克数",
        yueYongTianShu: "约用天数",
        meiRiWanShu: "每日丸数",
        meiWanKeShu: "每丸克数",
        fuYongCiShu: "服用次数",
        jitianhuan: "每%d天换一次",
      };
      if (shape == "1") {
        delete val["jitianhuan"];
      }
      if(shape == '3'){
        str = val.unit;
        return str;
      }
      for (var key in dic) {
        for (var key1 in val) {
          if (key == key1) {
            // str += dic[key].replace("%d", val[key1]) + "；" + "<br/>";
            if (key == "meiJiCiShu") {
              if (val.type == 1) {
                str += dic[key].replace("%s%d%r", "次" + val[key1] + val["unit"]) + "；" + "<br/>";
              } else {
                str += dic[key].replace("%s%d%r", "剂" + val[key1] + "次") + "；" + "<br/>";
              }
            } else if (key == "meiRiJiShu") {
              if (val.type == 1) {
                str += dic[key].replace("%d%r", val[key1] + "次") + "；" + "<br/>";
              } else {
                str += dic[key].replace("%d%r", val[key1] + "剂") + "；" + "<br/>";
              }
            } else {
              str += dic[key].replace("%d", val[key1]) + "；" + "<br/>";
            }
          }
        }
      }
      return str;
    },
    save(type) {
      var list = this.list.map((item) => {
        var obj = new Object();
        obj.KFiel_ID = item.KFiel_ID;
        obj.KFiel_Type = item.KFiel_Type;
        obj.KFiel_ShowCaption = item.KFiel_ShowCaption;
        obj.KDis_ID = item.KDis_ID;
        obj.Values = item.Values;
        obj.KOrder = item.KOrder;
        return obj;
      });
      var form = {};
      var str = "";
      if (type == "save") {
        //保存
        form = {
          id: this.feedbackid,
          list: JSON.stringify(list),
          photo: JSON.stringify(this.photo),
          change_state: 0,
          uid: this.serve_id,
        };
        str = "保存";
      } else {
        //提交
        form = {
          id: this.feedbackid,
          list: JSON.stringify(list),
          photo: JSON.stringify(this.photo),
          uid: this.serve_id,
        };
        str = "提交";
      }
      this.$confirm("此操作将" + str + "表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/field/insert_z_newback_record", this.qs.stringify(form))
            .then((res) => {
              if (res.data.code == "0") {
                this.$message({
                  message: "已" + str,
                  type: "success",
                });
                // this.EditZD = false;
              } else {
                this.$message({
                  message: str + "失败," + res.data.msg,
                  type: "success",
                });
              }
            })
            .catch((res) => {
              console.log(res);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    copy() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", "http://zd.51xk.info/index1.html?id=" + this.feedbackid);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message({
          type: "success",
          message: "复制成功",
        });
      }
      document.body.removeChild(input);
    },
    //点击方法
    shbsctp(html, kid, isone) {
      this.$emit("fkshenhetcout", this.dismain);
    },
    saveImage(html, kid, isOne) {
      html = document.getElementById("html2canvas");
      this.$refs.pdfbtn.saveImage(html, kid, isOne);
    },
  },
};
</script>
