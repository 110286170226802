<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>诊单管理</el-breadcrumb-item>
      <el-breadcrumb-item>反馈列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-select v-model="searchstate" placeholder="请选择状态" style="width:100px;margin-right:10px;" @change="getall(1, searchstate)" size="small">
        <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <!-- 反馈表编号： -->
      <el-input placeholder="请输入反馈表编号" clearable prefix-icon="el-icon-search" v-model="searchfiel" style="width: 200px;" @clear="getall(1, '1');searchstate=1;showpage=true;" size="small">
      </el-input>
      <el-button type="primary" style="margin-left:10px" @click="query" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%" v-loading="pdfloading" element-loading-text="正在生成PDF，请稍后...">
      <el-table-column prop="KID" label="反馈表编号" width="170"></el-table-column>
      <el-table-column prop="Dis_Name" label="反馈表名" width="170"></el-table-column>
      <el-table-column prop="Cust_ID" label="用户编号" width="120"></el-table-column>
      <el-table-column prop="Cust_Name" label="用户姓名" width="150"></el-table-column>
      <el-table-column prop="Input_ID" label="服务人ID" width="100"></el-table-column>
      <el-table-column prop="State" label="状态" width="100">
        <template slot-scope="scope">
          <span>{{ transformstate(scope.row.State) }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="220">
        <template slot-scope="scope">
          <div class="opt">
            <!-- <a @click="lookup(scope.row)">查看</a>
            <a @click="dialogFormVisible = true;feedbackid=scope.row.KID">修改状态</a> -->
            <p>
              <el-button size="mini" type="warning" icon="el-icon-view" @click="lookup(scope.row)">查看</el-button>
              <el-button size="mini" type="success" icon="el-icon-edit" @click="
                dialogFormVisible = true;
                row = scope.row;
              ">修改状态</el-button>
            </p>
            <p>
              <el-button v-if="scope.row.State == 6" size="mini" type="danger" icon="el-icon-printer" @click="shengchengpdf(scope.row)">生成pdf</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging" v-if="showpage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <feedback :lookfeedback.sync="lookfeedback" :feedbackid="feedbackid" :feedbacknoedit="feedbacknoedit" :shenhe.sync="shenhe" @fkshenhetcout="fkshenhetcout" ref="uploadfkpdf"></feedback>
    <el-dialog title="修改状态" :visible.sync="dialogFormVisible">
      <!-- <p>请选择{{row.KID}}要修改的状态</p> -->
      <p>患者 {{row.Cust_ID}} 的 {{row.Dis_Name}} 反馈表 {{row.KID}}</p>
      <el-select v-model="changestate" placeholder="请选择要修改的状态">
        <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button @click="
            dialogFormVisible = false;
            changestate = '';
          ">取 消</el-button>
        <el-button type="primary" @click="ModifyState">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import feedback from "../components/feedback.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      searchcustid: "",
      searchfiel: "",
      list: [],
      lookfeedback: false,
      feedbackid: "",
      feedbacknoedit: "",
      dialogFormVisible: false,
      changestate: "",
      options: [
        { id: 0, value: "未填写" },
        { id: 1, value: "提交" },
        { id: 2, value: "审核" },
        { id: 3, value: "打回" },
        { id: 6, value: "已下发" },
        { id: 9, value: "删除" },
      ],
      uid: sessionStorage.getItem("id"),
      servicerid: sessionStorage.getItem("servicer_id"),
      row: "",
      shenhe: false,
      pdfloading: false,
      searchstate: 1,
      showpage: true,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      iskefu: "no",
    };
  },
  components: {
    feedback,
  },
  mounted() {
    this.getall(1, "1");
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      if (this.searchfiel == "" && this.searchcustid == "") {
        this.getall(currentPage, this.searchstate);
      }
    },
    getall(page, state) {
      this.axios
        .get(
          "/field/get_z_newback_mains?state=" +
            state +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    transformstate(val) {
      var str = "";
      var dic = {
        0: "未填写",
        1: "提交",
        2: "审核",
        3: "打回",
        6: "已下发",
        9: "删除",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    query() {
      this.list = [];
      this.showpage = false;
      this.searchstate = "";
      if (this.searchfiel == "" && this.searchcustid == "") {
        this.$message({
          message: "请输入搜索条件",
          type: "error",
        });
        return;
      }
      if (
        (this.searchfiel !== "" ||
          this.searchfiel !== null ||
          this.searchfiel !== undefined) &&
        this.searchcustid == ""
      ) {
        console.log(this.searchfiel);
        this.axios
          .get("/field/get_z_newback_main_by_kid?kid=" + this.searchfiel)
          .then((res) => {
            if (res.data.code == 0) {
              this.list.push(res.data.result);
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    lookup(row) {
      // sessionStorage.setItem('Cust_ID', row.Cust_ID);
      // // sessionStorage.setItem('MedicalType', row.State);
      // this.$router.push({ path: "/FKOrderDetail", query: { id: row.KID } })
      this.lookfeedback = true;
      this.feedbackid = row.KID;
      this.feedbacknoedit = false;
      this.shenhe = false;
    },
    ModifyState() {
      if (this.changestate == 3) {
        this.changestate = 0;
      }
      if (
        this.changestate === "" ||
        this.changestate == null ||
        this.changestate == undefined
      ) {
        this.$message({
          message: "请选择状态",
          type: "error",
        });
        return;
      }
      if (
        this.row.KID == "" ||
        this.row.KID == null ||
        this.row.KID == undefined
      ) {
        this.$message({
          message: "没有KID",
          type: "error",
        });
        return;
      }
      if (
        this.servicerid == "" ||
        this.servicerid == null ||
        this.servicerid == undefined
      ) {
        this.$message({
          message: "没有服务ID",
          type: "error",
        });
        return;
      }
      if (this.changestate == 2) {
        this.shenhe = true;
        this.lookfeedback = true;
        this.feedbackid = this.row.KID;
        this.feedbacknoedit = false;
      } else {
        this.axios
          .post(
            "/field/update_z_newback_main_state",
            this.qs.stringify({
              state: this.changestate,
              kid: this.row.KID,
              uid: this.servicerid,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              // if (this.changestate == 2) {
              //   if (
              //     this.row.Cust_ID == "" ||
              //     this.row.Cust_ID == null ||
              //     this.row.Cust_ID == undefined
              //   ) {
              //     return;
              //   }
              //   if (
              //     this.row.Doc_ID == "" ||
              //     this.row.Doc_ID == null ||
              //     this.row.Doc_ID == undefined
              //   ) {
              //     return;
              //   }
              //   if (this.row.KID == "" || this.row.KID == null || this.row.KID == undefined) {
              //     return;
              //   }
              //   this.axios
              //     .post(
              //       "/gu/insert_t_hytl_ta",
              //       this.qs.stringify({
              //         x_cust_id: this.row.Cust_ID,
              //         doc_id: this.row.Doc_ID,
              //         kid: this.row.KID,
              //       })
              //     )
              //     .then((res) => {
              //       if (res.data.code == 0) {
              //         this.$message({
              //           message: "修改成功",
              //           type: "success",
              //         });
              //       } else {
              //         this.$message({
              //           message: res.data.msg,
              //           type: "error",
              //         });
              //       }
              //     });
              // }
              this.dialogFormVisible = false;
              // this.State = this.changestate;
              // sessionStorage.setItem("MedicalType", this.changestate);
              // this.reload();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (this.searchfiel != "") {
        // this.list = []; && this.searchcustid != ""
        this.query();
      }
      // this.query();
    },
    fkshenhetcout(row) {
      this.$confirm("此操作将审核反馈表, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.KID == "" || row.KID == null || row.KID == undefined) {
            return;
          }
          if (
            this.servicerid == "" ||
            this.servicerid == null ||
            this.servicerid == undefined
          ) {
            return;
          }
          this.axios
            .post(
              "/field/update_z_newback_main_state",
              this.qs.stringify({
                state: "2",
                kid: row.KID,
                uid: this.servicerid,
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.axios
                  .post(
                    "/gu/insert_t_hytl_ta",
                    this.qs.stringify({
                      x_cust_id: row.Cust_ID,
                      doc_id: row.Doc_ID,
                      kid: row.KID,
                    })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "审核成功",
                        type: "success",
                      });
                      this.$refs.uploadfkpdf.saveImage(
                        "html2canvas",
                        row.KID,
                        ""
                      );
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    shengchengpdf(row) {
      this.pdfloading = true;
      this.axios.get("/pdf/create_pdf_chufang?kid=" + row.KID).then((res) => {
        if (res.data.code == 0) {
          this.pdfloading = false;
          var url = "http://192.168.11.253:8080/public/" + res.data.file;
          window.open(url, "_blank");
        } else {
          this.pdfloading = false;
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.opt p{
  margin:0 0 8px 0;
}
</style>
